import React, { useRef } from "react";
import { Controller, Scene } from "react-scrollmagic";
import Placeholder from "../../../../core/Components/Placeholder";
import {
  calculateSpacing,
  getHorizontalAlign,
  getVerticalAlign,
  joinClasses,
} from "../../../../utils/getContainerClasses";
import useIsMobile from "../../../../utils/useIsMobile";
import useExpandingContainer from "../../../../utils/useExpandingContainer";
import MobileExternalBackground from "../common/MobileExternalBackground";
import buildBackgroundString from "../common/buildBackgroundString";
import useEnableBackgroundOnIntersection from "../common/useEnableBackgroundOnIntersection";

const OneColumnContainer = ({ rendering, fields }) => {
  const outsideRef = useRef(null);
  const containerRef = useRef(null);

  useExpandingContainer({
    expand: fields.expandingContainer?.value,
    outsideRef,
    containerRef,
  });

  const isMobile = useIsMobile();

  const { backgroundString, hasPatternBackground } = buildBackgroundString({
    isMobile,
    fields,
  });

  const hasBackground =
    !!backgroundString &&
    !(fields.backgroundMobileExternalPosition?.name && isMobile);

  const enableBackground = useEnableBackgroundOnIntersection({
    containerRef: outsideRef,
    hasBackground,
  });

  return (
    <section
      id={fields.id?.value || undefined}
      className={joinClasses(
        "column-container",
        fields.backgroundColor?.name && `bg-${fields.backgroundColor.name}`,
        (fields.backgroundImageRtlFlip?.value || hasPatternBackground) &&
          "background-image-rtl-flip",
        fields.roundedCorners?.value && "rounded",
        fields.fillHeight?.value && "full-height",
        fields.outerClassName?.value,
        calculateSpacing(fields),
      )}
      ref={outsideRef}
      style={
        hasBackground && enableBackground
          ? { background: backgroundString }
          : undefined
      }
    >
      <div
        className={fields.useContainer?.value ? "container" : undefined}
        ref={containerRef}
      >
        <div
          className={joinClasses(
            "row",
            getHorizontalAlign(fields.horizontalAlign?.name),
            getVerticalAlign(fields.verticalAlign?.name),
          )}
        >
          {isMobile &&
          backgroundString &&
          fields.backgroundMobileExternalPosition?.name === "before" ? (
            <MobileExternalBackground
              backgroundString={backgroundString}
              position="before"
            />
          ) : null}

          {fields.snapToGridContainer?.value ? (
            <Controller>
              <Scene
                triggerHook="onLeave"
                duration={`${fields.snapDuration?.value || "400%"}`}
                pin
              >
                {(progress) => (
                  <section
                    className={`pinContainer yellow panel-${fields.id?.value}`}
                  >
                    <Placeholder
                      name="one-column-content"
                      progress={progress}
                      rendering={rendering}
                    />
                  </section>
                )}
              </Scene>
            </Controller>
          ) : (
            <div className={`col-md-${fields.col?.value || 12}`}>
              <Placeholder name="one-column-content" rendering={rendering} />
            </div>
          )}

          {isMobile &&
          backgroundString &&
          fields.backgroundMobileExternalPosition?.name === "after" ? (
            <MobileExternalBackground
              backgroundString={backgroundString}
              position="after"
            />
          ) : null}
        </div>
      </div>
    </section>
  );
};

export default OneColumnContainer;
