import { useEffect, useState } from "react";

const useEnableBackgroundOnIntersection = ({ containerRef, hasBackground }) => {
  const [enableBackground, setEnableBackground] = useState(false);

  useEffect(() => {
    if (!hasBackground) {
      return;
    }

    const observer = new IntersectionObserver(
      (item) => {
        const isInView = item[0].isIntersecting;
        if (isInView && !enableBackground) {
          setEnableBackground(true);
        }
      },
      {
        root: null,
        rootMargin: "100px 0px",
        threshold: 0.2,
      },
    );

    if (containerRef.current) {
      observer.observe(containerRef.current);
    }

    return () => {
      if (containerRef.current) {
        observer.unobserve(containerRef.current);
      }
    };
  }, [hasBackground]);

  return enableBackground;
};

export default useEnableBackgroundOnIntersection;
